


















import { formatPercent } from '@/shared/formatters';
import { StrategyBacktestResult } from '@/types';
import { Component, Emit, Prop, Vue } from 'vue-property-decorator';

@Component({})
export default class BacktestResultSelect extends Vue {
  @Prop({ required: true }) backtestHistory!: StrategyBacktestResult[];

  @Prop({ required: false, default: '' }) selectedBacktestResultKey!: string;

  @Emit('selectionChange')
  setBacktestResult(key) {
    return key;
  }

  formatPercent = formatPercent;
}
