




























































































































































































































































































import { Component, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import TimeRangeSelect from '@/components/ftbot/TimeRangeSelect.vue';
import BacktestResultView from '@/components/ftbot/BacktestResultView.vue';
import BacktestResultSelect from '@/components/ftbot/BacktestResultSelect.vue';
import CandleChartContainer from '@/components/charts/CandleChartContainer.vue';
import StrategySelect from '@/components/ftbot/StrategySelect.vue';
import ValuePair from '@/components/general/ValuePair.vue';
import CumProfitChart from '@/components/charts/CumProfitChart.vue';
import TradesLogChart from '@/components/charts/TradesLog.vue';
import PairSummary from '@/components/ftbot/PairSummary.vue';
import TimeframeSelect from '@/components/ftbot/TimeframeSelect.vue';

import {
  BacktestPayload,
  BotState,
  PairHistoryPayload,
  PlotConfig,
  StrategyBacktestResult,
} from '@/types';

import { getCustomPlotConfig, getPlotConfigName } from '@/shared/storage';
import { formatPercent } from '@/shared/formatters';
import { BotStoreGetters } from '@/store/modules/ftbot';

const ftbot = namespace('ftbot');
@Component({
  components: {
    BacktestResultView,
    BacktestResultSelect,
    TimeRangeSelect,
    CandleChartContainer,
    CumProfitChart,
    TradesLogChart,
    StrategySelect,
    ValuePair,
    PairSummary,
    TimeframeSelect,
  },
})
export default class Backtesting extends Vue {
  pollInterval: number | null = null;

  showLeftBar = false;

  selectedTimeframe = '';

  selectedDetailTimeframe = '';

  strategy = '';

  timerange = '';

  enableProtections = false;

  maxOpenTrades = '';

  stakeAmountUnlimited = false;

  stakeAmount = '';

  startingCapital = '';

  btFormMode = 'run';

  selectedPlotConfig: PlotConfig = getCustomPlotConfig(getPlotConfigName());

  @ftbot.State backtestRunning!: boolean;

  @ftbot.State backtestStep!: string;

  @ftbot.Getter [BotStoreGetters.botState]?: BotState;

  @ftbot.State backtestProgress!: number;

  @ftbot.State backtestHistory!: StrategyBacktestResult[];

  @ftbot.State selectedBacktestResultKey!: string;

  @ftbot.State history;

  @ftbot.Getter [BotStoreGetters.selectedBacktestResult]!: StrategyBacktestResult;

  @ftbot.Getter [BotStoreGetters.canRunBacktest]!: boolean;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action getPairHistory!: (payload: PairHistoryPayload) => void;

  @ftbot.Action getState;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Action startBacktest!: (payload: BacktestPayload) => void;

  @ftbot.Action pollBacktest!: () => void;

  @ftbot.Action removeBacktest!: () => void;

  @ftbot.Action stopBacktest!: () => void;

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  @ftbot.Mutation setBacktestResultKey!: (key: string) => void;

  formatPercent = formatPercent;

  get hasBacktestResult() {
    return this.backtestHistory ? Object.keys(this.backtestHistory).length !== 0 : false;
  }

  get timeframe(): string {
    try {
      return this.selectedBacktestResult.timeframe;
    } catch (err) {
      return '';
    }
  }

  mounted() {
    this.getState();
  }

  setBacktestResult(key: string) {
    this.setBacktestResultKey(key);

    // Set parameters for this result
    this.strategy = this.selectedBacktestResult.strategy_name;
    this.selectedTimeframe = this.selectedBacktestResult.timeframe;
    this.selectedDetailTimeframe = this.selectedBacktestResult.timeframe_detail || '';
    this.timerange = this.selectedBacktestResult.timerange;
  }

  clickBacktest() {
    const btPayload: BacktestPayload = {
      strategy: this.strategy,
      timerange: this.timerange,
      // eslint-disable-next-line @typescript-eslint/camelcase
      enable_protections: this.enableProtections,
    };
    const openTradesInt = parseInt(this.maxOpenTrades, 10);
    if (openTradesInt) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      btPayload.max_open_trades = openTradesInt;
    }
    if (this.stakeAmountUnlimited) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      btPayload.stake_amount = 'unlimited';
    } else {
      const stakeAmount = Number(this.stakeAmount);
      if (stakeAmount) {
        // eslint-disable-next-line @typescript-eslint/camelcase
        btPayload.stake_amount = stakeAmount;
      }
    }

    const startingCapital = Number(this.startingCapital);
    if (startingCapital) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      btPayload.dry_run_wallet = startingCapital;
    }

    if (this.selectedTimeframe) {
      btPayload.timeframe = this.selectedTimeframe;
    }
    if (this.selectedDetailTimeframe) {
      // eslint-disable-next-line @typescript-eslint/camelcase
      btPayload.timeframe_detail = this.selectedDetailTimeframe;
    }

    this.startBacktest(btPayload);
  }

  @Watch('backtestRunning')
  backtestRunningChanged() {
    if (this.backtestRunning === true) {
      this.pollInterval = window.setInterval(this.pollBacktest, 1000);
    } else if (this.pollInterval) {
      clearInterval(this.pollInterval);
      this.pollInterval = null;
    }
  }
}
